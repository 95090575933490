/* eslint react/forbid-prop-types: 0 */
import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import {boldTextColor, blue} from '../modules/colors';

const Wrapper = styled.div`
  margin: 30px 20px 0 20px;
  background: #fff;
  padding: 10px 24px 20px 24px;
  box-shadow: 0 16px 48px #E2E8ED;
  display: flex;
  flex-direction: column;
  position: relative;

  ${props => props.tag && (`
    border: 2px solid ${blue};
  `)}
`;

const Tag = styled.div`
  background: ${blue};
  position: absolute;
  top: 0;
  align-self: center;
  color: white;
  padding: 4px 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 16px;
  font-weight: bold;
`;

const Title = styled.h4`
  font-size: 20px;
  color: ${boldTextColor};
  margin-top: 14px;
`;

const Card = ({
  title,
  children,
  tag,
  tagLabel
}) => (
  <Wrapper tag={tag}>
    {
      tag && <Tag>{tagLabel}</Tag>
    }
    {
      title && <Title>{title}</Title>
    }
    {children}
  </Wrapper>
);

Card.defaultProps = {
  tag: false,
  tagLabel: null,
  title: ''
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.bool,
  tagLabel: PropTypes.string,
  title: PropTypes.string
};

export default Card;
