import styled from 'styled-components/macro';
import media from '../modules/media';

export const Wrapper = styled.div`
  padding: 80px 20px;
  background: #F6F8FA;

  ${media.tablet`padding: 50px 15px;`};
  ${media.phone`padding: 20px 10px;`};
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  > div {
    width: 276px;
    max-width: 276px;
    flex-grow: 1;
    height: 160px;
  }
`;

export const CardContent = styled.div``;
