import React, {useContext, useState} from 'react';
import {navigate} from 'gatsby';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from './Button';
import request from '../modules/request';
import UserContext from '../modules/UserContext';
import authenticate from '../modules/authenticate';
import Card from './Card';
import Confirm from './Confirm';
// eslint-disable-next-line object-curly-newline
import {Wrapper, CardWrapper, CardPrice, CardFeature, ButtonWrapper} from './Pricing.styles';

const Pricing = () => {
  const user = useContext(UserContext);
  const [confirmDetails, setConfirmDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async newPlan => {
    setIsLoading(true);

    // show a loading toast, because we have an artificial 5sec delay on that request to
    // allow stripe to send the webhook events
    toast.info('Please wait...', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 10000
    });

    // change plan
    const response = await request(`user/change-plan/${newPlan}`);
    setIsLoading(false);

    // handle the server response
    if (response.status === 200) {
      if (typeof window !== 'undefined') {
        window.location.reload();
      }
    } else {
      const responseJSON = await response.json();
      toast.error(responseJSON.error, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  const handleFreeClick = () => {
    if (user.email && user.plan !== 'free') {
      setConfirmDetails({
        open: true,
        title: 'Are you sure you want to change to the Free plan?',
        message: `If your APIs use more that ${process.env.FREE_REQUESTS} requests per month, they will stop working!`,
        onClose: () => setConfirmDetails({open: false}),
        onYes: async () => {
          handleChange('free');
        }
      });
    } else {
      authenticate();
    }
  };

  const handlePlusClick = async () => {
    if (user.email) {
      if (user.cards[0]) {
        handleChange('plus');
      } else {
        navigate('/add-card?plan=plus');
      }
    } else {
      authenticate();
    }
  };

  const handleProClick = async () => {
    if (user.email) {
      if (user.cards[0]) {
        handleChange('pro');
      } else {
        navigate('/add-card?plan=pro');
      }
    } else {
      authenticate();
    }
  };

  let freeButtonText = 'Get started';
  let plusButtonText = 'Get started';
  let proButtonText = 'Get started';
  if (user.email) {
    if (user.plan === 'free') {
      plusButtonText = 'Select';
      proButtonText = 'Select';
    } else if (user.plan === 'plus') {
      freeButtonText = 'Select';
      proButtonText = 'Select';
    } else if (user.plan === 'pro') {
      freeButtonText = 'Select';
      plusButtonText = 'Select';
    }
  }

  return (
    <Wrapper id="pricing">
      <CardWrapper>
        <Card title="Free" tag={user.plan === 'free'} tagLabel="Active">
          <CardPrice>
            <span>$</span>
            <span>0</span>
            <span>/month</span>
          </CardPrice>
          <CardFeature>{`${process.env.FREE_REQUESTS} requests/month`}</CardFeature>
          <CardFeature>Create Read Update Delete</CardFeature>
          <CardFeature>Unlimited spreadsheets</CardFeature>
          <CardFeature>Unlimited rows per spreadsheet</CardFeature>
          {user.plan !== 'free' && (
            <ButtonWrapper>
              <Button onClick={handleFreeClick} disabled={isLoading}>
                {freeButtonText}
              </Button>
            </ButtonWrapper>
          )}
        </Card>
        <Card title="Plus" tag={user.plan === 'plus'} tagLabel="Active">
          <CardPrice>
            <span>$</span>
            <span>9</span>
            <span>/month</span>
          </CardPrice>
          <CardFeature>{`${Number(process.env.PLUS_REQUESTS).toLocaleString()} requests/month`}</CardFeature>
          <CardFeature>Create Read Update Delete</CardFeature>
          <CardFeature>Unlimited spreadsheets</CardFeature>
          <CardFeature>Unlimited rows per spreadsheet</CardFeature>
          <CardFeature>Human Support</CardFeature>
          {user.plan !== 'plus' && (
            <ButtonWrapper>
              <Button onClick={handlePlusClick} disabled={isLoading}>
                {plusButtonText}
              </Button>
            </ButtonWrapper>
          )}
        </Card>
        <Card title="Pro" tag={user.plan === 'pro'} tagLabel="Active">
          <CardPrice>
            <span>$</span>
            <span>79</span>
            <span>/month</span>
          </CardPrice>
          <CardFeature>{`${Number(process.env.PRO_REQUESTS).toLocaleString()} requests/month`}</CardFeature>
          <CardFeature>Create Read Update Delete</CardFeature>
          <CardFeature>Unlimited spreadsheets</CardFeature>
          <CardFeature>Unlimited rows per spreadsheet</CardFeature>
          <CardFeature>Human Support</CardFeature>
          {user.plan !== 'pro' && (
            <ButtonWrapper>
              <Button onClick={handleProClick} disabled={isLoading}>
                {proButtonText}
              </Button>
            </ButtonWrapper>
          )}
        </Card>
      </CardWrapper>
      <Confirm {...confirmDetails} />
    </Wrapper>
  );
};

export default Pricing;
