import styled from 'styled-components/macro';
import {blue} from '../modules/colors';
import media from '../modules/media';

export const Wrapper = styled.div`
  padding: 100px;
  width: 100%;
  max-width: 600px;

  ${media.tablet`padding: 70px;`};
  ${media.phone`padding: 50px;`};
`;

export const Header = styled.h2`
  padding-bottom: 20px;
`;

export const Item = styled.div`
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.1px;

  &::before, &:last-child::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #DEE3E9;
  }
`;

export const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;
  cursor: pointer;
  color: #202932;
  font-weight: 700;
`;

export const Icon = styled.div`
  position: relative;
  width: 16px;
  height: 16px;

  &::before, &::after {
    content: '';
    position: absolute;
    background-color: ${blue};
    transition: -webkit-transform .25s ease-out;
    transition: transform .25s ease-out;
    transition: transform .25s ease-out, -webkit-transform .25s ease-out;
  }

  &::before {
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    ${props => props.open && 'transform: rotate(90deg)'};
  }

  &::after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    margin-top: -1px;
  }
`;

export const Answer = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  margin-bottom: 24px;
`;
