import React from 'react';
import {Link} from 'gatsby';
import Button from './Button';
import {Wrapper, Container, Inner, Copy, Video, Title, Subtitle} from './Hero.styles';

const Hero = () => (
  <Wrapper>
    <Container>
      <Inner>
        <Copy>
          <Title>Create a GraphQL API from your Google Spreadsheet</Title>
          <Subtitle>Create a CMS/BackEnd for your app in less than a minute</Subtitle>
          <Link to="/create">
            <Button onClick={null}>Try for FREE</Button>
          </Link>
        </Copy>
        <Video>
          <iframe
            title="GraphQLsheet Intro"
            src="https://www.youtube.com/embed/HoGqvJdGnPw"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Video>
      </Inner>
    </Container>
  </Wrapper>
);

export default Hero;
