import React, {useState} from 'react';
import {Wrapper, Header, Item, Question, Icon, Answer} from './FAQ.styles';

const faqs = [
  {
    question: 'What is GraphQLsheet',
    answer: `We help you instantly create a GraphQL from your Google Spreadsheet.
You can think of your spreadsheet as a database that you will be able to read/write/update/delete data.
You can also go to the spreadsheet and manually add data, making it an actual CMS (Content Management System).`
  },
  {
    question: 'Why should I use GraphQLsheet?',
    answer: `You should use GraphQLsheet: If you are a FrontEnd or Mobile developer
and you want a quick and easy solution to manage your application content.
If you are confortable with spreadsheets and you want to manage your app/website content through it,
your developers will very easy time using it since it's based on GraphQL. If you already have
a lot of data in a spreadsheet and you are looking for an easy way to provide them to an
app or a website.`
  },
  {
    question: 'How does it work?',
    answer: `We ask for your permission to connect to your Google Drive account and analyze the
spreadsheet you want to create the API for. We use the first row an keys and the rest as values.
We analyze every value and if for example a column (except for the first row) has only numbers,
we understand that this is a numeric field. We use that info to create a GraphQL schema for your
spreadsheet and then create the actual API for you.`
  },
  {
    question: 'What will happen if I modify the spreadsheet manually?',
    answer: `That's perfectly fine! If you add more columns or modify the first row,
you just need to recreate the API (it's just a button click). If you add more rows, you will be able to
access them through the API immediately, which makes the GraphQLsheet API a headless CMS.`
  },
  {
    question: 'Are my data secure?',
    answer: `Yes. We authenticate through the Google OAuth process. We never store any data
(except for the first row) on our servers. For anyone to access the data through our API,
they must use the API key (token) that is unique for each spreadsheet. Share the API key only with
people you trust.`
  }
];

const FAQ = () => {
  const [open, setOpen] = useState([false, false]);

  const handleClick = clickedItemIndex => {
    const newState = [...open];
    newState[clickedItemIndex] = !newState[clickedItemIndex];
    setOpen(newState);
  };

  return (
    <Wrapper id="faq">
      <Header>FAQs</Header>
      {faqs.map((faq, index) => (
        <Item key={faq.question}>
          <Question onClick={() => handleClick(index)}>
            {faq.question}
            <Icon open={open[index]} />
          </Question>
          <Answer show={open[index]}>{faq.answer}</Answer>
        </Item>
      ))}
    </Wrapper>
  );
};

export default FAQ;
