import React from 'react';
import styled from 'styled-components/macro';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Features from '../components/Features';
import FAQ from '../components/FAQ';
import Pricing from '../components/Pricing';
import '../app.css';

const Main = styled.div`
  flex: 1 0 auto;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const IndexPage = () => (
  <Layout>
    <Main>
      <Hero />
      <Features />
      <Wrapper>
        <FAQ />
      </Wrapper>
      <Pricing />
    </Main>
  </Layout>
);

export default IndexPage;
