import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {blue, pink} from '../modules/colors';

const Inner = styled.button`
  background: ${pink};
  font-size: 15px;
  letter-spacing: 0px;
  font-weight: 700;
  line-height: 16px;
  color: white;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  justify-content: center;
  padding: 16px 32px;
  text-align: center;
  white-space: nowrap;
  position: relative;
  transition: background-color 0.5s ease 0s;

  &:hover:not(:disabled) {
    background: ${blue};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const Help = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  padding: 4px;
`;

const Button = props => {
  const {help} = props;
  const {children} = props;

  return (
    <Inner type="button" {...props}>
      {
        help && <Help data-tip={help}>?</Help>
      }
      {children}
    </Inner>
  );
};

Button.defaultProps = {
  help: null,
  children: null
};

Button.propTypes = {
  help: PropTypes.string,
  children: PropTypes.node
};

export default Button;
