import styled from 'styled-components/macro';
import {boldTextColor, lightGray} from '../modules/colors';
import tick from '../images/tick.svg';
import media from '../modules/media';

export const Wrapper = styled.div`
  padding: 80px 20px;
  background: #F6F8FA;

  ${media.tablet`padding: 50px 15px;`};
  ${media.phone`padding: 20px 10px;`};
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

export const CardPrice = styled.div`
  font-size: 18px;
  border-bottom: 1px solid ${lightGray};
  padding-bottom: 20px;
  margin-bottom: 20px;

  > :nth-child(2) {
    font-size: 40px;
    color: ${boldTextColor};
  }
`;

export const CardFeature = styled.div`
  margin-bottom: 14px;
  padding-left: 30px;
  background: url(${tick}) no-repeat;
  background-position: left;
`;

export const ButtonWrapper = styled.div`
  align-self: center;
  margin-top: auto;
`;
