import styled from 'styled-components/macro';
import media from '../modules/media';
import {boldTextColor} from '../modules/colors';

export const Wrapper = styled.div`
  text-align: left;
  padding: 92px 0 80px 0;
  position: relative;

  ${media.tablet`padding: 30px 0 20px 0`}

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0;
    height: 100%;
    width: 40%;
    background: #2294fb;
    background: linear-gradient(to top right, #0081f6 0, #44a6ff 100%);

    ${media.tablet`display: none;`}
  }
`;

export const Container = styled.div`
  max-width: 1128px;
  width: 100%;
  margin: 0 auto;
`;

export const Inner = styled.div`
  position: relative;
  display: flex;

  ${media.tablet`
    flex-direction: column;
    align-content: center;
  `}
`;

export const Copy = styled.div`
  padding: 20px;
  width: 55%;

  ${media.tablet`width: auto;`}
`;

export const Title = styled.h1`
  color: ${boldTextColor};
  font-size: 40px;

  ${media.tablet`font-size: 32px;`};
  ${media.phone`font-size: 24px;`};
`;

export const Subtitle = styled.div`
  font-size: 20px;
  margin: 20px 0;
  padding: 10px;
  white-space: pre-wrap;

  ${media.tablet`
    font-size: 18px;
    margin: 10px 0;
  `};

  ${media.phone`
    font-size: 15px;
    margin: 0;
  `};
`;

export const Video = styled.div`
  overflow: hidden;
  ${media.tablet`
    display: flex;
    justify-content: center;
  `};

  > iframe {
    width: 472px;
    height: 315px;

    ${media.desktop`
      width: 284px;
      height: 189px;
      padding: 80px 0 0 20px;
    `}

    ${media.tablet`
      width: 472px;
      height: 315px;
      padding: 20px 0 0 0;
    `}

    ${media.phone`
      width: 284px;
      height: 189px;
      padding: 20px 0 0 0;
    `}
  }
`;
