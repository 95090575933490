import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import Card from './Card';
import Button from './Button';
import Modal from './Modal';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
`;

const Confirm = ({
  message,
  noLabel,
  onClose,
  onNo,
  onYes,
  title,
  yesLabel,
  open
}) => {
  const handleNo = () => {
    onClose();
    onNo();
  };

  const handleYes = () => {
    onClose();
    onYes();
  };

  return (
    <Modal onClose={onClose} open={open}>
      <Card title={title}>
        <div>{message}</div>
        <ButtonWrapper>
          <Button onClick={handleNo}>{noLabel}</Button>
          <Button onClick={handleYes}>{yesLabel}</Button>
        </ButtonWrapper>
      </Card>
    </Modal>
  );
};

Confirm.defaultProps = {
  title: '',
  message: '',
  yesLabel: 'Yes',
  noLabel: 'No',
  onClose: () => {},
  onYes: () => {},
  onNo: () => {},
  open: false
};

Confirm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  open: PropTypes.bool
};

export default Confirm;
