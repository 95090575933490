import React from 'react';
import Card from './Card';
import {
  Wrapper,
  CardWrapper,
  CardContent
} from './Features.styles';

const cards = [{
  title: '1 click setup',
  content: 'Create a GraphQL API from a Google Spreadsheet simply by using the spreadsheet URL. We will do the rest!'
}, {
  title: 'A familiar CMS',
  content: `Use a Google Spreadsheet as a Content Management System.
It's simple, flexible and you are already familiar with it.`
}, {
  title: 'Full CRUD',
  content: 'Create, Read, Update and Delete data to your Google spreadsheet through the GraphQL API.'
}, {
  title: 'Easy Collaboration',
  content: 'Google Drive already provides a full collaboration platform to have colleagues work on your spreadsheets.'
}];

const Features = () => (
  <Wrapper id="features">
    <CardWrapper>
      {
        cards.map(card => (
          <Card key={card.title} title={card.title}>
            <CardContent>{card.content}</CardContent>
          </Card>
        ))
      }
    </CardWrapper>
  </Wrapper>
);

export default Features;
