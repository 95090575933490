import React, {useEffect} from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import {lightGray} from '../modules/colors';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  display: ${props => (props.open ? 'block' : 'none')};
`;

const Close = styled.div`
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.7;
  z-index: 11;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &::before, &::after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

const Background = styled.div`
  background: ${lightGray};
  opacity: 0.9;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 8;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 9;
  overflow: scroll;
`;

const Modal = ({
  children,
  onClose,
  open
}) => {
  const addEscapeListener = event => {
    if (event.keyCode === 27) {
      onClose();
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', addEscapeListener);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('keydown', addEscapeListener);
      }
    };
  }, [onClose]);

  return (
    <Wrapper open={open}>
      <Close onClick={() => onClose()} />
      <Content>
        {children}
      </Content>
      <Background />
    </Wrapper>
  );
};

Modal.defaultProps = {
  onClose: () => {}
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Modal;
